import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import FormData from 'form-data'
import forEach from 'lodash/forEach'
import upperCase from 'lodash/upperCase'
import startCase from 'lodash/startCase'
import replace from 'lodash/replace'
import join from 'lodash/join'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'

// Base Page
import BasePage from '@/pages/Base'

// Local Components
import ProductDetail from '@/containers/ProductDetail/ProductDetail.vue'

// Services
import SuppliersService from '@/services/Suppliers'
import { propsBinder } from 'vue2-leaflet'

@Component({
  components: { ProductDetail }
})
export default class Products extends BasePage {
  constructor() {
    super()
  }

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search By Supplier Name...'
  searchHint: string = ''
  searchIcon: string = 'search'

  // Sync Product
  // addSupplierModal: boolean = false
  supplierDialog: boolean = false
  supplierDialogData: any = {}

  alert_SupplierDelete: boolean = false
  idDelete = null

  syncProductDialog: boolean = false
  syncProductId: string = ''
  maxSyncProductCharacter: number = 25

  // Table
  tableHeaders: object[] = [
    {
      text: 'Supplier Name',
      align: 'left',
      sortable: true,
      value: 'supplierName'
    },
    {
      text: 'Total Products',
      align: 'center',
      sortable: true,
      value: 'totalProducts'
    },
    {
      text: 'Country Origin',
      align: 'center',
      sortable: true,
      value: 'countryOrigin'
    },
    {
      text: 'Website',
      align: 'center',
      sortable: true,
      value: 'website'
    },
    // {
    //   text: 'Supplier Client',
    //   align: 'center',
    //   sortable: true,
    //   value: 'supplierClient'
    // },
    {
      text: 'PIC Details',
      align: 'center',
      sortable: true,
      value: 'picDetails'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'status',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    // sortBy: '-id',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false
  clientItem: any = []
  // countryOriginItems: any = [
  //   {
  //     id: 1,
  //     name: 'Indonesia'
  //   },
  //   {
  //     id: 2,
  //     name: 'China'
  //   },
  //   {
  //     id: 3,
  //     name: 'Singapore'
  //   }
  // ]
  countryOriginItems: any = []
  useAsEdit: boolean = false
  searchCountryDebounce: Function
  searchUserDebounce: Function

  async mounted() {
    await this.getSupplierLists()
    // this.getClientLimit()
    // this.searchCountryDebounce = debounce(this.getCountryOrigin, 500)
    // this.searchUserDebounce = debounce(this.getClient, 500)
  }

  @Watch('tablePagination', { deep: true })
  @Watch('searchInput', { deep: true })
  @Watch('supplierDialogData.keywordCountry')
  @Watch('supplierDialogData.keywordClient')
  async onChanged() {
    this.$vuetify.goTo(0)
    this.searchCountryDebounce = debounce(this.getCountryOrigin, 500)
    this.searchUserDebounce = debounce(this.getClient, 500)
    this.searchCountryDebounce()
    this.searchUserDebounce()
    await this.getSupplierLists()
  }

  openSupplierDialog() {
    this.resetData()
    this.getCountryOrigin()
    this.getClient()
    this.useAsEdit = false
    this.supplierDialog = true
  }

  openEditSupplierDialog(props: any) {
    this.getCountryOrigin()
    this.getClient()
    this.supplierDialogData = {
      id: props.item.id,
      supplierName: props.item.name,
      countryOrigin: {
        id: props.item.country_id,
        name: props.item.country_name
      },
      // countryOrigin: '',
      website: props.item.website,
      client: props.item.current_client,
      payload_client: props.item.client_array,
      picName: props.item.pic_details[0].value,
      picPhone: props.item.pic_details[1].value,
      picEmail: props.item.pic_details[2].value,
      refund_policy: props.item.refund_policy,
      warranty_policy: props.item.warranty_policy,
      purchase_note: props.item.purchase_note,
      loadingCountry: false,
      loadingClient: false,
      keywordCountry: null,
      keywordClient: null,
      editPicDetail: props.item.pic_details
    }
    this.useAsEdit = true
    this.supplierDialog = true
  }

  resetData() {
    this.supplierDialogData = {
      supplierName: '',
      countryOrigin: '',
      website: '',
      client: [],
      payload_client: [],
      picName: '',
      picPhone: '',
      picEmail: '',
      refund_policy: '',
      warranty_policy: '',
      purchase_note: '',
      loadingCountry: false,
      loadingClient: false,
      keywordCountry: null,
      keywordClient: null
    }
  }

  async getSupplierLists() {

    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        sort: this.tablePagination.descending ? '-id' : 'id',
        include: 'categories,supplier,source,variations,images,users'
      }
    }

    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[name][like]': this.searchInput
      }
    } else {
      var textFilter = 'filter[name][like]'
      delete opts.params[textFilter]
    }

    this.tableLoading = true
    try {
      var response = await SuppliersService.getSuppliers(opts)
      var responseData = response.data
      var responseMeta = response.meta
      var responseIncluded = response.included

      this.tableItems = []
      forEach(responseData, dataSupplier => {
        // var returnSupplier = this.getSupplierClient(response, dataSupplier)
        // var supplier_client = returnSupplier[0]
        // var supplier_array = returnSupplier[1]
        // var current_supplier = returnSupplier[2]
        var pic_details = ''

        if (typeof dataSupplier.attributes.pic_details === 'string') {
          dataSupplier.attributes.pic_details = JSON.parse(dataSupplier.attributes.pic_details)
        }

        // forEach(dataSupplier.attributes.pic_details, picDetails => {
        //   if (picDetails.value == null) {
        //     pic_details += `-<br/><br/>`
        //     // pic_details += `-, `
        //   } else {
        //     pic_details += `${picDetails.value}<br/><br/>`
        //   }
        // })

        var allNull = true
        forEach(dataSupplier.attributes.pic_details, picDetails => {
          if (picDetails.value !== null) {
            pic_details += `${picDetails.value} `
            allNull = false
          }
          else {
            allNull = true
            // pic_details += `-<br/><br/>`
            // pic_details += `-, `
          }
        })

        if (allNull === true) {
          pic_details = '-'
        }

        const supplier = dataSupplier.attributes
        supplier.country_name = responseIncluded.country[supplier.country_id].attributes.name
        supplier.pic_text = pic_details
        supplier.actionButton = false
        // supplier.client = supplier_client
        // supplier.client_array = supplier_array
        // supplier.current_client = current_supplier

        this.tableItems.push(supplier)
      })
      this.tableTotalItems = responseMeta.pagination.total
      this.tableLoading = false

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  // Supplier Delete
  onSupplierDeleted(id) {
    this.alert_SupplierDelete = true
    this.idDelete = id
  }

  async supplierDeleted() {
    this.showLoading({ text: 'Deleting...' })
    try {
      const response = await SuppliersService.deleteDeleteSupplier(this.idDelete)

      if (response.data.success) {
        await this.getSupplierLists()

        this.showSnackbar({
          text: 'Deleted Successfully!',
          color: 'teal',
          timeout: 1500
        })
      } else {
        this.showSnackbar({
          text: 'Delete Failed!',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.idDelete = null
      this.alert_SupplierDelete = false
      this.closeLoading()
    }
  }

  // Get Seupplier Client per Supplier
  getSupplierClient(response, dataSupplier) {
    var client_text = ''
    var client_array = []
    var client_selected = []
    var dataClient = dataSupplier
    for (const relationshipsClient of dataClient.relationships.users) {
      var id_client = relationshipsClient.id
      // client += (response.included.users[id_client].attributes.name + " (" + response.included.users[id_client].attributes.email + ")<br/>")
      client_text += (response.included.users[id_client].attributes.name + '<br/>')
      const client: any = {
        name: response.included.users[id_client].attributes.name + ' (' + response.included.users[id_client].attributes.email + ')',
        id: response.included.users[id_client].attributes.id
      }
      client_selected.push(client)
      client_array.push(response.included.users[id_client].attributes.id)
    }

    return [client_text, client_array, client_selected]

  }

  // async getClientLimit() {
  //   const opts: any = {
  //     params: {
  //       include: "items,invoices,platforms,parent,child"
  //     }
  //   }

  //   try {
  //     const response = await SuppliersService.getClient(opts)
  //     this.getClient(response.data.meta.pagination.total)
  //   } catch (error) {
  //     this.catchHandler(error)
  //   }
  // }

  async getClient() {
    // const opts: any = {
    //   params: {
    //     include: "items,invoices,platforms,parent,child",
    //     // "page[limit]": 100
    //   }
    // }

    try {
      // if (isEmpty(this.supplierDialogData.client)) {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.supplierDialogData.keywordClient)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.supplierDialogData.keywordClient
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await SuppliersService.getClient(opts)

      const dataClient = response.data.data

      for (const clientList of dataClient) {
        const client: any = {
          name: clientList.attributes.name + ' (' + clientList.attributes.email + ')',
          id: clientList.attributes.id
        }
        this.clientItem.push(client)
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getCountryOrigin() {
    this.supplierDialogData.loadingCountry = true
    try {
      // if (this.supplierDialogData.countryOrigin === '') {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.supplierDialogData.keywordCountry)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.supplierDialogData.keywordCountry
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await SuppliersService.getCountry(opts)

      const dataCountry = response.data.data

      for (const countryList of dataCountry) {
        const country: any = {
          name: countryList.attributes.name,
          id: countryList.attributes.id
        }
        this.countryOriginItems.push(country)
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.supplierDialogData.loadingCountry = false
    }
  }

  onChangeClient() {
    this.supplierDialogData.payload_client = this.supplierDialogData.client
  }

  async addSupplier() {
    try {
      const validationResponse = await this.$validator.validateAll('supplierDialogScope')
      if (validationResponse) {

        this.supplierDialog = false
        this.showLoading({ text: 'Creating...' })

        const dialogData = {
          name: this.supplierDialogData.supplierName,
          total_product: 0,
          website: this.supplierDialogData.website,
          pic_details: [
            {
              label: 'name',
              value: this.supplierDialogData.picName
            },
            {
              label: 'phone',
              value: this.supplierDialogData.picPhone
            },
            {
              label: 'email',
              value: this.supplierDialogData.picEmail
            }
          ],
          country_id: this.supplierDialogData.countryOrigin.id,
          user_id: this.supplierDialogData.payload_client,
          refund_policy: this.supplierDialogData.refund_policy,
          warranty_policy: this.supplierDialogData.warranty_policy,
          purchase_note: this.supplierDialogData.purchase_note
        }

        await SuppliersService.setSupplier(dialogData)

        this.closeLoading()
        this.showSnackbar({
          text: 'Supplier Created Successfully!',
          color: 'green',
          timeout: 2500
        })
        this.getSupplierLists()
      } else {
        this.showSnackbar({
          text: 'Please check all filed(s) requirements',
          color: 'red',
          timeout: 3500
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async editSupplier() {
    try {
      const validationResponse = await this.$validator.validateAll('supplierDialogScope')
      if (validationResponse) {
        this.supplierDialog = false
        this.showLoading({ text: 'Creating...' })

        const dialogData = {
          name: this.supplierDialogData.supplierName,
          total_product: 0,
          website: this.supplierDialogData.website,
          // pic_details: [
          //   {
          //     label: 'name',
          //     value: this.supplierDialogData.picName
          //   },
          //   {
          //     label: 'phone',
          //     value: this.supplierDialogData.picPhone
          //   },
          //   {
          //     label: 'email',
          //     value: this.supplierDialogData.picEmail
          //   }
          // ],
          pic_details: this.supplierDialogData.editPicDetail,
          country_id: this.supplierDialogData.countryOrigin.id,
          user_id: this.supplierDialogData.payload_client,
          refund_policy: this.supplierDialogData.refund_policy,
          warranty_policy: this.supplierDialogData.warranty_policy,
          purchase_note: this.supplierDialogData.purchase_note
        }

        await SuppliersService.updateSupplier(this.supplierDialogData.id, dialogData)

        this.closeLoading()
        this.showSnackbar({
          text: 'Supplier Updated Successfully!',
          color: 'green',
          timeout: 2500
        })
        this.resetData()
        this.getSupplierLists()
      } else {
        this.showSnackbar({
          text: 'Please check all field(s) requirements',
          color: 'red',
          timeout: 3500
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}